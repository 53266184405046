const DBConfig = {
  name: "Metro-hot-line",
  version: 1,
  objectStoresMeta: [
    {
      store: "products",
      storeConfig: { keyPath: "id" },
      storeSchema: [
        { name: "id", keypath: "id", options: { unique: true } },
        { name: "allPrice", keypath: "allPrice", options: { unique: false } },
        { name: "image", keypath: "image", options: { unique: false } },
        { name: "interval", keypath: "interval", options: { unique: false } },
        { name: "name_ar", keypath: "name_ar", options: { unique: false } },
        { name: "name_en", keypath: "name_en", options: { unique: false } },
        { name: "branches", keypath: "branches", options: { unique: false } },
        {
          name: "cuttingTypes",
          keypath: "cuttingTypes",
          options: { unique: false },
        },
        { name: "unit", keypath: "unit", options: { unique: false } },
      ],
    },{
      
    }
  ],
};

export default DBConfig;
