import { message } from "antd";
import axios from "axios";
const url = process.env.REACT_APP_BASE_API_URL; 

export const addUser = (body,onSucess, onFail) => {
    axios({ 
        method: 'post',
        url: `${url}offline/users/add`,
        data: body,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
            'Content-Type': 'multipart/form-data;', 
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`
        }
        })
        .then((response) => onSucess(response))
        .catch(err => onFail(err)); 
}

export const addAdress = (body,onSucess, onFail) => {
    axios({ 
        method: 'post',
        url: `${url}offline/users/addresses/add`,
        data: body,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
            'Content-Type': 'multipart/form-data;', 
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`
        }
        })
        .then((response) => onSucess(response))
        .catch(err => onFail(err)); 
}

export const editAdress = (body,onSucess, onFail) => {
    axios({ 
        method: 'post',
        url: `${url}offline/users/addresses/change`,
        data: body,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
            'Content-Type': 'multipart/form-data;', 
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`
        }
        })
        .then((response) => onSucess(response))
        .catch(err => onFail(err)); 
}

export const addPhone = (body,onSucess, onFail) => {
    axios({ 
        method: 'post',
        url: `${url}offline/users/phones/add`,
        data: body,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
            'Content-Type': 'multipart/form-data;', 
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`
        }
        })
        .then((response) => onSucess(response))
        .catch(err => onFail(err)); 
}

export const changeAdress = (body,onSucess, onFail) => {
    axios({ 
        method: 'post',
        url: `${url}offline/users/addresses/change`,
        data: body,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
            'Content-Type': 'multipart/form-data;', 
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`
        }
        })
        .then((response) => onSucess(response))
        .catch(err => onFail(err)); 
}

export const changeBranch = (body,onSucess, onFail) => {
    axios({ 
        method: 'post',
        url: `${url}offline/users/branches/select`,
        data: body,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
            'Content-Type': 'multipart/form-data;', 
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`
        }
        })
        .then((response) => onSucess(response))
        .catch(err => onFail(err)); 
}

export const getBranchServe = (brancheId, onSucess, onFail) => {
    fetch(`${url}offline/users/branches/${brancheId}`, { 
        method: "GET",
        headers: {
            "Content-Type": "multipart/form-data", 
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`, 
        }, 
    })
    .then(response => {  
        if (!response.ok) {
            message.error('لا يمكن الوصول إلى موقع الويب. الرجاء التحقق من اتصال الانترنت الخاص بك');
        }
        return response.json();
    })
    .then(json => onSucess(json))
    .catch(err => onFail(err));
}

export const getCities = (onSucess, onFail) => {
    fetch(`${url}offline/users/branches/cities`, { 
        method: "GET",
        headers: {
            "Content-Type": "multipart/form-data", 
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`, 
        }, 
    })
    .then(response => {  
        if (!response.ok) {
            message.error('لا يمكن الوصول إلى موقع الويب. الرجاء التحقق من اتصال الانترنت الخاص بك');
        }
        return response.json();
    })
    .then(json => onSucess(json))
    .catch(err => onFail(err));
}

export const searchUser = (query ,onSucess, onFail) => {
    fetch(`${url}offline/users/search?phone=${query}`, {
        method: "GET",
        headers: {
            "Content-Type": "multipart/form-data", 
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`, 
        }, 
    })
    .then(response => {  
        if (!response.ok) {
            message.error('لا يمكن الوصول إلى موقع الويب. الرجاء التحقق من اتصال الانترنت الخاص بك');
        }
        return response.json();
    })
    .then(json => onSucess(json))
    .catch(err => onFail(err));
}

export const getProducts = (onSucess, onFail) => {
    axios({ 
        method: 'GET',
        url: `${url}offline/products/get`,
        headers: {
            "Content-Type": "multipart/form-data", 
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`, 
        }
        })
        .then((response) => onSucess(response))
        .catch(err => onFail(err)); 
}

export const getAllBranches = (onSucess, onFail) => { 
    fetch(`${url}offline/users/branches/get/all`, {
        method: "GET",
        headers: {
            "Content-Type": "multipart/form-data", 
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`, 
        }, 
    })
    .then(response => {  
        if (!response.ok) {
            message.error('لا يمكن الوصول إلى موقع الويب. الرجاء التحقق من اتصال الانترنت الخاص بك');
        }
        return response.json();
    })
    .then(json => onSucess(json))
    .catch(err => onFail(err));
}

export const getAlternatives = (query ,onSucess, onFail) => {
    fetch(`${url}offline/products/alternatives/${query.productId}?id=${query.customerId}`, {
        method: "GET",
        headers: {
            "Content-Type": "multipart/form-data", 
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`, 
        }, 
    })
    .then(response => {  
        if (!response.ok) {
            message.error('لا يمكن الوصول إلى موقع الويب. الرجاء التحقق من اتصال الانترنت الخاص بك');
        }
        return response.json();
    })
    .then(json => onSucess(json))
    .catch(err => onFail(err));
}

export const checkoutOrder = (body, onSucess, onFail) => {
    axios({ 
        method: 'post',
        url: `${url}offline/checkout`,
        data: JSON.stringify(body),
        headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
                'Content-Type': 'application/json', 
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`
            }
        })
        .then((response) => onSucess(response))
        .catch(err => onFail(err)); 
}

export const deleteUserAddress = (body, onSucess, onFail) => {
    axios({ 
        method: 'post',
        url: `${url}offline/users/addresses/delete`,
        data: JSON.stringify(body),
        headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
                'Content-Type': 'application/json', 
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`
            }
        })
        .then((response) => onSucess(response))
        .catch(err => onFail(err)); 
}

export const deleteUserPhone = (body, onSucess, onFail) => {
    axios({ 
        method: 'post',
        url: `${url}offline/users/phones/delete`,
        data: JSON.stringify(body),
        headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
                'Content-Type': 'application/json', 
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`
            }
        })
        .then((response) => onSucess(response))
        .catch(err => onFail(err)); 
}

export const editUserPhone = (body, onSucess, onFail) => {
    axios({ 
        method: 'post',
        url: `${url}offline/users/phones/change`,
        data: body, 
        headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
                'Content-Type': 'application/json', 
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`
            }
        })
        .then((response) => onSucess(response))
        .catch(err => onFail(err)); 
}

export const editUserName = (body, onSucess, onFail) => {
    axios({ 
        method: 'post',
        url: `${url}offline/users/change`, 
        data: body, 
        headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
                'Content-Type': 'application/json', 
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`
            }
        })
        .then((response) => onSucess(response))
        .catch(err => onFail(err)); 
}