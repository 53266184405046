import { Modal, Form, Select } from "antd";
import React, { Component } from "react";
const { Option } = Select;

class SelectBranchModal extends Component {
  branchesRef = React.createRef();
  constructor() {
    super();
    this.state = {
      selectedBranch: null,
      loading: false,
    };
  }

  handelSelectCity = (_, option) => {
    this.setState({ selectedBranch: option });
  };

  handelAddBranch = () => {
    this.setState({ loading: true });
    this.props.addServeBranch({
      selectedBranch: this.state.selectedBranch,
    });
    setTimeout(() => {
      this.setState({ loading: false, selectedBranch: null });
      this.props.closeModal();
    }, 1300);
  };

  render() {
    const { showModal, closeModal, branchesList } = this.props;
    const { selectedBranch, loading } = this.state;
    return (
      <>
        {showModal && (
          <Modal
            visible={showModal}
            centered
            onOk={this.handelAddBranch}
            okText="اضف الفرع"
            wrapClassName="selcet-brnach-modal"
            confirmLoading={loading}
            okButtonProps={{ disabled: !!!selectedBranch }}
            onCancel={() => closeModal()}
            destroyOnClose
          >
            <Form name="branch" ref={this.branchesRef} layout="vertical">
              <div className="input-holder">
                <Form.Item
                  name="branches"
                  label="اختر الفرع"
                  rules={[
                    {
                      required: true,
                      message: "من فضلك اختر الفرع",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    onChange={(value, option) =>
                      this.handelSelectCity(value, option)
                    }
                    filterOption={(input, option) =>
                      option.children[2]
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="الفروع"
                  >
                    {branchesList.map((branche) => (
                      <Option key={branche.id} value={branche.id}>
                        {branche.name + " ,"}&nbsp;&nbsp;&nbsp;
                        {branche.address}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Form>
          </Modal>
        )}
      </>
    );
  }
}

export default SelectBranchModal;
