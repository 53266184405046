import axios from "axios";

const url = process.env.REACT_APP_BASE_API_URL; 

export const userLogin = (body, onSucess, onFail) => {
    axios({ 
        method: 'post',
        url: `${url}offline/auth/login`,
        data: body,
        headers: {'Content-Type': 'multipart/form-data;'}
        })
        .then((response) => onSucess(response))
        .catch(err => onFail(err)); 
}
