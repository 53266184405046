import { LogoutOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Home from "./pages/homepage/Home";
import Login from "./pages/homepage/login";
import "./scss/app.scss";
class App extends Component {
  handelLogout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("citiesList");
    localStorage.removeItem("productList");
    localStorage.removeItem("token");
    this.props.history.push("/login");
  }
  componentDidMount() {
    if (!!localStorage.getItem("userInfo") === false) {
      this.props.history.push("/login");
    }

  }

  render() {
    return (
      <div className="App">
        <div className="app-wrapper">
          {localStorage.getItem("userInfo") && <p className="user-auth-title">مرحبا : {JSON.parse(localStorage.getItem("userInfo")).name} <LogoutOutlined onClick={this.handelLogout} style={{ color: 'red', fontSize: 20, marginRight: 10, cursor: 'pointer' }} /></p>}
          <div className="app-top-bg"></div>
          <div className="app-bottom-bg"></div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
