import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { initDB, IndexedDB } from "react-indexed-db";
import DBConfig from "./DB/db.config";
initDB(DBConfig);
ReactDOM.render(
  <IndexedDB
    name="Metro-hot-line"
    version="1"
    objectStoresMeta={[
      {
        store: "products",
        storeConfig: { keyPath: "id" },

        storeSchema: [
          { name: "id", keypath: "id", options: { unique: true } },
          { name: "allPrice", keypath: "allPrice", options: { unique: false } },
          { name: "image", keypath: "image", options: { unique: false } },
          { name: "interval", keypath: "interval", options: { unique: false } },
          { name: "name_ar", keypath: "name_ar", options: { unique: false } },
          { name: "name_en", keypath: "name_en", options: { unique: false } },
          { name: "branches", keypath: "branches", options: { unique: false } },
          {
            name: "cuttingTypes",
            keypath: "cuttingTypes",
            options: { unique: false },
          },
          { name: "unit", keypath: "unit", options: { unique: false } },
        ],
      },
    ]}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </IndexedDB>,
  document.getElementById("root")
);
