
export const phoneValidation = [
    {
      required: true,
      message: "من فضلك ادخل رقم الهاتف",
    },
    {
      min: 8,
      message: "يجب أن يتكون الهاتف من 8 أرقام على الأقل",
    },
    {
      max: 11,
      message : "لا يمكن أن يكون الهاتف أطول من 11 رقمًا"
    },
    {
      pattern: /^[0-9\b]+$/,
      message: "يجب ان يحتوي رقم الهاتف علي ارقام فقط",
    },
  ]