import React, { Component } from "react";
import { Form, Input, Button } from "antd";
import { userLogin } from "../../network/authentication.network";
import { withRouter } from "react-router-dom";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      btnLoading: false,
      isLoginValid: false,
    };
  }

  onFinish = (values) => {
    this.setState({btnLoading : true})
    let body = new FormData();
    body.append("username", values.username);
    body.append("password", values.password);
    userLogin(
      body,
      (res) => {
        this.setState({btnLoading : false})
        if (res.data.validation.length > 0) { 
          this.setState({isLoginValid : true})
        }
        if(res.data.code === 200){
            localStorage.setItem("userInfo", JSON.stringify(res.data.object.user));
            localStorage.setItem("token", JSON.stringify(res.data.message));
            setTimeout(() => {
                this.props.history.push("/");
            }, 300)
        }
      },
      (error) => {
        console.log(error);  
        this.setState({btnLoading : false})
      }
    );
    
  };
  render() {
    const { btnLoading, isLoginValid } = this.state;
    return (
      <div className="app__container app__container--login">
        {isLoginValid && <p style={{fontSize : 15, color: 'red', textAlign:'center', marginBottom : 5}}>اسم مستخدم أو كلمة مرور غير صالحة</p>}
        <Form
          name="basic"
          onFinish={this.onFinish}
        >
          <div className="input-holder">
            <Form.Item
              label="اسم المستخدم"
              name="username"
              rules={[
                {
                  required: true,
                  message: "الرجاء إدخال اسم المستخدم الخاص بك!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="input-holder">
            <Form.Item
              label="كلمة المرور"
              name="password"
              rules={[
                {
                  required: true,
                  message: "الرجاء إدخال كلمة المرور الخاصة بك!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </div>

          <Form.Item className="login-btn">
            <Button type="primary" htmlType="submit" loading={btnLoading}>
              تسجيل الدخول
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withRouter(Login);
