/* eslint-disable import/first */
import React, { Component } from "react";
import {
  Button,
  Input,
  Modal,
  Form,
  Select,
  Radio,
  Collapse,
  AutoComplete,
  Result,
  message,
  Spin,
  Divider,
} from "antd";

import 'antd/dist/antd.css';


import SelectBranchModal from "../../components/branchesModal/SelectBranchModal";
const { Search } = Input;
const { Option } = Select;
const { Panel } = Collapse;
const { confirm } = Modal;
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CommentOutlined,
  DeleteFilled,
  ArrowLeftOutlined,
  CaretLeftOutlined,
  CalendarOutlined,
  EditOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  getCities,
  addUser,
  searchUser,
  getProducts,
  addAdress,
  changeAdress,
  changeBranch,
  checkoutOrder,
  getAlternatives,
  deleteUserAddress,
  getAllBranches,
  editAdress,
  addPhone,
  deleteUserPhone,
  editUserPhone,
  editUserName,
} from "../../network/home.network";
import imgPlacholder from "../../assets/placeholder.png";
import moment from "moment";
import { phoneValidation } from "../../constants/constants";
import { AccessDB } from "react-indexed-db";



class Home extends Component {
  formRef = React.createRef();
  formAddPhonesRef = React.createRef();
  formAddressRef = React.createRef();
  formCommentRef = React.createRef();
  formEditName = React.createRef();
  constructor() {
    super();
    this.googleInput = React.createRef();
    this.state = {
      productListDB: [],
      productListDBTemp: [],
      searchLoading: false,
      showValidation: false,
      addCustomerVisible: false,
      addAddressVisible: false,
      sendOrderLoading: false,
      citiesList: [],
      customerData: null,
      openAutoComplete: true,
      selectAdressIdx: 0,
      searchVal: "",
      showCustomerNoData: false,
      customerBrnanchServe: null,
      isCustomerNew: false,
      isEditBrnach: false,
      isEditAddress: false,
      showSelcetBrnchModal: false,
      autoCompleteItem: "",
      AltProductList: [],
      selectedProductList: [],
      orderStepActive: 1,
      orderComment: "",
      orderCommentTemp: "",
      payMethod: 1,
      loadingAddUser: false,
      showAltParagraph: false,
      showAltModal: false,
      loadingAlt: false,
      alternativeProductSelected: null,
      alternativeProductSelectedIndx: null,
      searchProLoading: false,
      isProductsUpdated: false,
      generalOrderVisible: false,
      showHistoryOrder: false,
      addPhonesModal: false,
      editPhonesMode: false,
      addNameVisible: false,
      editPhoneVal: null,
      branchesList: [],
      showDeleteProduct: false,
      dbUpdatedAt: localStorage.getItem("dbUpdatedAt") ?? "00:00",
      productListIdx: -1,
    };
  }

  componentDidMount() {
    const { citiesList, branchesList } = this.state;
    if (!!localStorage.getItem("userInfo") === false) {
      this.props.history.push("/login");
    }
    this.setState({ userInfo: JSON.parse(localStorage.getItem("userInfo")) });

    const localCitiesList = JSON.parse(localStorage.getItem("citiesList"));
    const localBranchesList = JSON.parse(localStorage.getItem("branchesList"));
    const localState = JSON.parse(localStorage.getItem("localState"));

    if (!!!localCitiesList) {
      citiesList.length < 1 && this.fetchCitiesList();
    } else {
      this.setState({ citiesList: localCitiesList });
    }

    if (!!!localBranchesList) {
      branchesList.length < 1 && this.fetchAllBranches();
    } else {
      this.setState({ branchesList: localBranchesList });
    }

    if (!!!localState) {
      // if null
    } else {
      // if exist
      const {
        customerData,
        searchVal,
        showCustomerNoData,
        isCustomerNew,
        selectAdressIdx,
        customerBrnanchServe,
        selectedProductList,
        orderStepActive,
        orderComment,
        orderCommentTemp,
        payMethod,
        alternativeProductSelected,
        alternativeProductSelectedIndx,
      } = localState;
      this.setState({
        customerData,
        searchVal,
        showCustomerNoData,
        isCustomerNew,
        selectAdressIdx,
        customerBrnanchServe,
        selectedProductList,
        orderStepActive,
        orderComment,
        orderCommentTemp,
        payMethod,
        alternativeProductSelected,
        alternativeProductSelectedIndx,
      });
    }
  }

  componentDidUpdate(_, prevState) {
    if (prevState.orderStepActive !== this.state.orderStepActive) {
      if (this.state.orderStepActive === 2) {
        this.handelListWithPrice();
      }
    }
  }

  handelListWithPrice = () => {
    var allProPrice;
    if (
      this.state.productListDB.length > 0 &&
      this.state.productListDB.allProPrice === undefined
    ) {
      let productWithPrice = [...this.state.productListDB]
        .filter((val) => val.branches.length !== 0)
        .map((pro) => {
          if (this.state.customerData && this.state.customerData.branch) {
            allProPrice = pro.branches.find(
              (branch) => branch.branch_id === this.state.customerData.branch.id
            );
          }
          allProPrice = allProPrice ? allProPrice : pro.branches[0];
          return { ...pro, allPrice: { ...allProPrice } };
        });
      this.setState({ productListDB: productWithPrice });
      this.setState({ productListDBTemp: productWithPrice });
    }
  };

  checkTimeForUpdate = (add, getAll, clear) => {
    var format = "HH:mm";
    let timeNow = moment(new Date(), format);
    let lastUpdated = moment(this.state.dbUpdatedAt, format);
    let isUpdated = false;
    let updateTimes = [
      {
        start: moment("00:00", format),
        end: moment("06:59", format),
      },
      {
        start: moment("07:00", format),
        end: moment("12:59", format),
      },
      {
        start: moment("13:00", format),
        end: moment("18:59", format),
      },
      {
        start: moment("19:00", format),
        end: moment("23:59", format),
      },
    ];
    for (const updateTime of updateTimes) {
      if (
        timeNow.isBetween(updateTime.start, updateTime.end) &&
        !lastUpdated.isBetween(updateTime.start, updateTime.end)
      ) {
        this.setState({
          dbUpdatedAt: timeNow.format(format),
        });
        localStorage.setItem("dbUpdatedAt", timeNow.format(format));
        clear();
        this.fetchProductsList(add, getAll);
        isUpdated = true;
      }
    }
    if (!isUpdated) {
      this.fetchProductsList(add, getAll);
    }
  };

  fetchCitiesList = () => {
    getCities(
      (res) => {
        this.setState({ citiesList: res.object });
        localStorage.setItem("citiesList", JSON.stringify(res.object));
      },
      (error) => {
        console.log(error);
      }
    );
  };
  async addAllToDB(add, list) {
    for (let i = 0; i < list.length; i++) {
      await add(list[i]);
    }
  }
  fetchAllBranches = () => {
    getAllBranches(
      (res) => {
        this.setState({ branchesList: res.object });
        localStorage.setItem("branchesList", JSON.stringify(res.object));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  fetchProductsList = (add, getAll) => {
    this.setState({ searchProLoading: true });
    getAll().then((res) => {
      if (res.length) {
        this.setState({ productListDB: res });
        this.setState({ productListDBTemp: res });
        this.setState({ searchProLoading: false }, () => {
          if (
            this.state.customerData &&
            this.state.customerData.branch !== undefined
          ) {
            this.handelListWithPrice();
          }
        });
      } else {
        getProducts(
          (res) => {
            this.setState({ productListDB: res.data.object });
            this.setState({ productListDBTemp: res.data.object });
            this.setState({ searchProLoading: false }, () => {
              if (
                this.state.customerData &&
                this.state.customerData.branch !== undefined
              ) {
                this.handelListWithPrice();
              }
            });

            this.addAllToDB(add, res.data.object);
          },
          (error) => {
            this.setState({ searchProLoading: false });
            console.log(error);
          }
        );
      }
    });
  };

  addUserInfoToLocalStorage = () => {
    const {
      customerData,
      searchVal,
      showCustomerNoData,
      isCustomerNew,
      selectAdressIdx,
      customerBrnanchServe,
      selectedProductList,
      orderStepActive,
      orderComment,
      orderCommentTemp,
      payMethod,
      alternativeProductSelected,
      alternativeProductSelectedIndx,
    } = this.state;

    const localState = {
      customerData,
      searchVal,
      showCustomerNoData,
      isCustomerNew,
      selectAdressIdx,
      customerBrnanchServe,
      selectedProductList,
      orderStepActive,
      orderComment,
      orderCommentTemp,
      payMethod,
      alternativeProductSelected,
      alternativeProductSelectedIndx,
    };
    try {
      var serialized = JSON.stringify(localState);
      localStorage.setItem("localState", serialized);
    } catch (error) {
      console.log(error);
    }
  };

  onSearch = (value) => {
    if (!value) return;
    if (value.length >= 8 && value.length <= 11) {
      this.setState({
        searchLoading: true,
        customerData: null,
        selectedProductList: [],
      });
      localStorage.removeItem("localState");
      searchUser(
        value,
        (res) => {
          if (res.object) {
            const selectAdressIdx = res.object.addresses.findIndex(
              (address) => address.selected === true
            );
            this.setState({
              searchLoading: false,
              customerData: res.object,
              showCustomerNoData: false,
              selectAdressIdx: selectAdressIdx < 0 ? 0 : selectAdressIdx,
            });
          } else {
            this.setState({
              searchLoading: false,
              showCustomerNoData: true,
            });
          }
        },
        (error) => {
          this.setState({ searchLoading: false });
          console.log(error);
        }
      );
    } else {
      this.setState({ showValidation: true });
    }
  };

  handelSearchChange = (e) => {
    const telNo = e.target.value;
    const re = /^[0-9\b]+$/;
    if (telNo === "" || re.test(telNo)) {
      this.setState({ searchVal: e.target.value });
    }
    if ((telNo.length >= 8 && telNo.length <= 11) || telNo.length === 0) {
      this.setState({ showValidation: false });
    }
  };

  handelEditPhones = () => {
    const {
      name,
    } = this.state.customerData;
    this.setState({ addPhonesModal: true }, () => {
      setTimeout(() => {
        this.formAddPhonesRef.current.setFieldsValue({
          name,
        });
      }, 500);
    });
  };

  handelEditName = () => {
    const edieName = this.formEditName.current.getFieldValue("editName");
    let body = {
      name: edieName,
      id: this.state.customerData.id,
    };
    editUserName(
      body,
      (res) => {
        if (res.data.validation) {
          this.setState({ loadingAddUser: false });
          res.data.validation.forEach((element) => {
            message.error(element);
          });
        }
        if (res.data.code === 200) {
          message.success("تم تعديل الاسم بنجاح");
          this.setState({
            customerData: {
              ...this.state.customerData,
              name: edieName,
            },
            addNameVisible: false,
          });
        }
      },
      (err) => {
        this.setState({ addNameVisible: false });
        console.log(err);
      }
    );
  };

  handelSubmitPhones = (values) => {
    this.setState({ loadingAddUser: true });
    let body = new FormData();
    body.append("phone", values.phone);
    body.append("id", this.state.customerData.id);
    if (this.state.editPhonesMode) {
      body.append("phoneId", this.state.editPhoneVal.id);
      body.append("name", values.name);
      editUserPhone(
        body,
        (res) => {
          if (res.data.validation) {
            this.setState({ loadingAddUser: false });
            res.data.validation.forEach((element) => {
              message.error(element);
            });
          }
          if (res.data.code === 200) {
            message.success("تم تعديل الرقم بنجاح");
            this.setState({
              customerData: {
                ...this.state.customerData,
                otherPhones: res.data.object.filter(
                  (phone) => phone.id !== this.state.customerData.phones.id
                ),
              },
              editPhonesMode: false,
              addPhonesModal: false,
              loadingAddUser: false,
              editPhoneVal: null,
            });
          }
        },
        (error) => {
          this.setState({
            editPhonesMode: false,
            addPhonesModal: false,
            loadingAddUser: false,
            editPhoneVal: null,
          });
          console.log(error);
        }
      );
    } else {
      addPhone(
        body,
        (res) => {
          if (res.data.validation) {
            this.setState({ loadingAddUser: false });
            res.data.validation.forEach((element) => {
              message.error(element);
            });
          }
          if (res.data.code === 200) {
            message.success("تم اضافه الرقم بنجاح");
            this.setState({
              customerData: {
                ...this.state.customerData,
                otherPhones: res.data.object.filter(
                  (phone) => phone.id !== this.state.customerData.phones.id
                ),
              },
              editPhonesMode: false,
              addPhonesModal: false,
              loadingAddUser: false,
            });
          }
        },
        (error) => {
          this.setState({
            editPhonesMode: false,
            addPhonesModal: false,
            loadingAddUser: false,
          });
          console.log(error);
        }
      );
    }
  };

  handelAddCustomerModal = (modalMode) => {
    if (modalMode === "edit-mode") {
      const {
        phones: { phone },
        name,
        addresses,
      } = this.state.customerData;
      const { building, city, apartment, floor, area, streetNumber, landmark } =
        addresses[this.state.selectAdressIdx];
      this.setState({ addCustomerVisible: true }, () => {
        setTimeout(() => {
          this.formRef.current.setFieldsValue({
            phone,
            building,
            city,
            apartment,
            floor,
            area,
            streetNumber,
            landmark,
            name,
          });
        }, 200);
      });
    } else {
      this.setState({ addCustomerVisible: true }, () => {
        if (!this.state.showCustomerNoData) {
          this.resetAllData();
        }
        setTimeout(() => {
          this.formRef.current.setFieldsValue({
            phone: this.state.searchVal,
          });
        }, 200);
      });
    }
  };

  selectServeBranch = () => {
    this.setState({ showSelcetBrnchModal: true });
  };
  closeSelectServeBranch = () => {
    this.setState({ showSelcetBrnchModal: false, isEditBrnach: false });
  };

  addServeBranch = (brnachData) => {
    let body = new FormData();
    body.append("branchId", +brnachData.selectedBranch.value);
    body.append("id", this.state.customerData.id);
    changeBranch(
      body,
      (res) => {
        if (res.data.validation) {
          res.data.validation.forEach((element) => {
            message.error(element);
          });
        }
        if (res.data.code === 200) {
          message.success("تم اختيار الفرع بنجاح");

          this.setState(
            {
              customerData: {
                ...this.state.customerData,
                branch: res.data.object,
              },
            },
            () => {
              if (this.state.productListDB.length > 0) {
                let productWithPrice = [...this.state.productListDB].map(
                  (pro) => {
                    let allProPrice = pro.branches.find(
                      (branch) =>
                        branch.branch_id === this.state.customerData.branch.id
                    );
                    allProPrice = allProPrice ? allProPrice : pro.branches[0];
                    return { ...pro, allPrice: { ...allProPrice } };
                  }
                );
                this.setState({ productListDB: productWithPrice });
                this.setState({ productListDBTemp: productWithPrice });
              }
            }
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  onChangeAdress = (e) => {
    this.setState({ selectAdressIdx: e.target.value });
  };

  handelDecrementAmount = (productIndx) => {
    const selectedProductList = this.state.selectedProductList;
    const selectedProduct = this.state.selectedProductList[productIndx];
    const { val, interval, price, totalPrice, intervalPoint } = selectedProduct;
    if (+val === +intervalPoint) return;
    const incVal = +val - +intervalPoint;
    const incPrice = +incVal * +price;
    const incSelectedProduct = {
      ...selectedProduct,
      totalPrice: incPrice,
      val: incVal,
    };
    selectedProductList[productIndx] = incSelectedProduct;
    this.setState({ selectedProductList });
  };

  handelChangeAmount = (e, productIndx) => {
    var productChangeVal = e.target.value;
    if (productChangeVal === "") {
      productChangeVal = 1;
    } else {
      if (productChangeVal?.includes("e")) return;
    }
    const selectedProductList = this.state.selectedProductList;
    const selectedProduct = this.state.selectedProductList[productIndx];
    const { price } = selectedProduct;
    const incVal = +productChangeVal;
    const incPrice = +incVal * +price;
    const incSelectedProduct = {
      ...selectedProduct,
      totalPrice: incPrice,
      val: incVal,
    };
    selectedProductList[productIndx] = incSelectedProduct;
    this.setState({ selectedProductList });
  };

  handelIncreseAmount = (productIndx) => {
    const selectedProductList = this.state.selectedProductList;
    const selectedProduct = this.state.selectedProductList[productIndx];
    const { val, price, intervalPoint } = selectedProduct;
    const incVal = +val + +intervalPoint;
    const incPrice = +incVal * +price;
    const incSelectedProduct = {
      ...selectedProduct,
      totalPrice: incPrice,
      val: incVal,
    };
    selectedProductList[productIndx] = incSelectedProduct;
    this.setState({ selectedProductList });
  };

  handelDecrementAlt = (productIndx) => {
    const selectedProductList = this.state.AltProductList;
    const updateProductListAfterIncrese = this.state.selectedProductList;
    const selectedProduct = selectedProductList[productIndx];
    const { val, interval, price, totalPrice, intervalPoint } = selectedProduct;
    if (+val === +intervalPoint) return;
    const incVal = +val - +intervalPoint;
    const incPrice = +incVal * +price;
    const incSelectedProduct = {
      ...selectedProduct,
      totalPrice: incPrice,
      val: incVal,
    };
    const updateProductAltIdx = [...this.state.selectedProductList].findIndex(
      (pro) => pro.isAlt === true
    );
    updateProductListAfterIncrese[updateProductAltIdx] = incSelectedProduct;
    selectedProductList[productIndx] = incSelectedProduct;
    this.setState({
      AltProductList: selectedProductList,
      selectedProductList: updateProductListAfterIncrese,
    });
  };

  handelIncreseAlt = (productIndx) => {
    const selectedProductList = this.state.AltProductList;
    const updateProductListAfterIncrese = this.state.selectedProductList;
    const selectedProduct = selectedProductList[productIndx];
    const { val, price, intervalPoint } = selectedProduct;
    const incVal = +val + +intervalPoint;
    const incPrice = +incVal * +price;
    const incSelectedProduct = {
      ...selectedProduct,
      totalPrice: incPrice,
      val: incVal,
    };
    const updateProductAltIdx = [...this.state.selectedProductList].findIndex(
      (pro) => pro.isAlt === true
    );
    updateProductListAfterIncrese[updateProductAltIdx] = incSelectedProduct;
    selectedProductList[productIndx] = incSelectedProduct;
    this.setState({ AltProductList: selectedProductList });
  };

  onSelectProduct = (name, product) => {
    const isProductInExist = this.state.selectedProductList.some(
      (pro) => pro.id === product.product.id
    );
    const isProductInBranch = product.product.branches.some(
      (pro) => pro.branch_id === this.state.customerData.branch.id
    );
    if (!isProductInExist) {
      if (isProductInBranch) {
        const selectedBranchIndex = product.product.branches.findIndex(
          (pro) => pro.branch_id === this.state.customerData.branch.id
        );
        const price = product.product.branches[selectedBranchIndex || 0].price;
        const old_price =
          product.product.branches[selectedBranchIndex || 0].old_price;
        const productList = [...this.state.selectedProductList];
        productList.push({
          ...product.product,
          val: product.product.interval[0],
          intervalPoint: product.product.interval[0],
          price,
          old_price,
          totalPrice: price * +product.product.interval[0],
          comment: "",
          isAlt: false,
          cuttingTypesSelect: product.product.cuttingTypes[0]
            ? product.product.cuttingTypes[0].id
            : null,
        });
        this.setState({
          selectedProductList: productList,
          autoCompleteItem: "",
        });
        setTimeout(() => {
          this.addUserInfoToLocalStorage();
        }, 1500);
      } else {
        this.setState({
          showAltParagraph: true,
          alternativeProductSelected: product,
          openAutoComplete: false,
        });
      }
    } else {
      message.warning("المنتج تم اختياره بالفعل");
      this.setState({
        openAutoComplete: false,
      });
    }
  };

  onChangeProduct = (value) => {
    this.setState({ autoCompleteItem: value, showAltParagraph: false });
  };

  showDeleteConfirm = (idx) => {
    const { selectedProductList } = this.state;
    const modal = confirm();
    modal.update({
      title: "هل أنت متأكد من حذف هذا المنتج؟",
      icon: <ExclamationCircleOutlined style={{ color: "#f60606" }} />,
      content: "",
      okText: "نعم",
      okType: "danger",
      cancelText: "لا",
      wrapClassName: "delete-modal-wrapper",
      onOk: () => {
        const filterSelectedProductList = selectedProductList.filter(
          (_, index) => index !== idx
        );
        this.setState({ selectedProductList: filterSelectedProductList }, () =>
          this.forceUpdate()
        );
        modal.destroy();
      },
      onCancel: () => {
        modal.destroy();
      },
    });
  };

  handelAddCommentToItem = (e, productIndx) => {
    const selectedProductList = this.state.selectedProductList;
    const selectedProduct = this.state.selectedProductList[productIndx];
    const newProduct = { ...selectedProduct, comment: `${e.target.value}` };
    selectedProductList[productIndx] = newProduct;
    this.setState({ selectedProductList });
  };

  handelChangecuttingTypes = (typeVal, type) => {
    const selectedProductList = this.state.selectedProductList;
    const selectedProduct = this.state.selectedProductList[type.productIdx];
    const updateSelectedProduct = {
      ...selectedProduct,
      cuttingTypesSelect: typeVal,
    };
    selectedProductList[type.productIdx] = updateSelectedProduct;
    this.setState({ selectedProductList });
  };

  handelAddCustomer = (values) => {
    const {
      phone,
      name,
      building,
      city,
      apartment,
      floor,
      area,
      streetNumber,
      landmark,
      phones,
    } = values;
    let body = new FormData();
    body.append("name", name);
    body.append("phones[0]", phone);
    body.append("city", city);
    body.append("area", area);
    body.append("streetNumber", streetNumber);
    body.append("building", building);
    body.append("apartment", apartment ? apartment : "");
    body.append("floor", floor ? floor : "");
    body.append("landmark", landmark ? landmark : "");
    phones &&
      phones.forEach((altPhone, idx) => {
        body.append(`phones[${idx + 1}]`, altPhone.phone);
      });

    if (this.state.customerData) {
      this.setState({ loadingAddUser: true });
      body.append("id", this.state.customerData.id);
      body.append(
        "addressId",
        this.state.customerData.addresses[this.state.selectAdressIdx].id
      );
      changeAdress(
        body,
        (res) => {
          if (res.data.validation) {
            this.setState({ loadingAddUser: false });
            res.data.validation.forEach((element) => {
              message.error(element);
            });
          }
          if (res.data.code === 200) {
            message.success("تمت تعديل العنوان بنجاح");
            this.setState({
              customerData: {
                ...this.state.customerData,
                addresses: res.data.object,
                phone,
                name,
              },
              addAddressVisible: false,
              selectAdressIdx: res.data.object.findIndex(
                (address) => address.selected === true
              ),
              searchVal: phone,
              loadingAddUser: false,
              addCustomerVisible: false,
              showCustomerNoData: false,
            });
          }
        },
        (error) => {
          this.setState({ loadingAddUser: false });
          console.error(error);
        }
      );
    } else {
      this.setState({ loadingAddUser: true });
      addUser(
        body,
        (res) => {
          if (res.data.validation) {
            this.setState({ loadingAddUser: false });
            res.data.validation.forEach((element) => {
              message.error(element);
            });
          }
          if (res.data.code === 200) {
            message.success("تمت إضافة العميل بنجاح");
            this.setState({
              customerData: res.data.object,
              addAddressVisible: false,
              selectAdressIdx: 0,
              searchVal: phone,
              addCustomerVisible: false,
              showCustomerNoData: false,
              loadingAddUser: false,
              isCustomerNew: true,
            });
          }
        },
        (error) => {
          this.setState({ loadingAddUser: false });
          console.error(error);
        }
      );
    }
  };

  handelAddAddress = (values) => {
    const { building, city, apartment, floor, area, streetNumber, landmark } =
      values;
    let body = new FormData();
    body.append("id", this.state.customerData.id);
    body.append("city", city);
    body.append("area", area);
    body.append("streetNumber", streetNumber);
    body.append("building", building);
    body.append("apartment", apartment ? apartment : "");
    body.append("floor", floor ? floor : "");
    body.append("landmark", landmark ? landmark : "");
    this.setState({ loadingAddUser: true });
    if (this.state.isEditAddress) {
      body.append("addressId", this.state.isEditAddress.id);
      editAdress(body, (res) => {
        if (res.data.validation) {
          this.setState({ loadingAddUser: false });
          res.data.validation.forEach((element) => {
            message.error(element);
          });
        }
        if (res.data.code === 200) {
          message.success("تمت تعديل العنوان بنجاح");
          this.setState({
            addAddressVisible: false,
            loadingAddUser: false,
            customerData: {
              ...this.state.customerData,
              addresses: res.data.object,
            },
          });
        }
      });
    } else {
      addAdress(
        body,
        (res) => {
          if (res.data.validation) {
            this.setState({ loadingAddUser: false });
            res.data.validation.forEach((element) => {
              message.error(element);
            });
          }
          if (res.data.code === 200) {
            message.success("تمت إضافة العنوان بنجاح");
            this.setState({
              addAddressVisible: false,
              loadingAddUser: false,
              selectAdressIdx: res.data.object.findIndex(
                (address) => address.selected === true
              ),
              customerData: {
                ...this.state.customerData,
                addresses: res.data.object,
              },
            });
          }
        },
        (error) => {
          console.error("error", error);
          this.setState({ loadingAddUser: false });
        }
      );
    }
  };

  addDefaultSrc = (ev) => {
    ev.target.src = imgPlacholder;
  };

  resetAllData = () => {
    localStorage.removeItem("localState");
    this.setState({
      orderStepActive: 1,
      customerData: null,
      searchVal: "",
      orderComment: "",
      orderCommentTemp: "",
      selectedProductList: [],
      AltProductList: [],
      isCustomerNew: false,
      customerBrnanchServe: null,
      selectAdressIdx: 0,
      alternativeProductSelectedIndx: null,
      alternativeProductSelected: null,
      payMethod: 1,
    });
  };

  handelAlternativesList = () => {
    const { alternativeProductSelected, customerData } = this.state;
    this.setState({ showAltModal: true, loadingAlt: true });
    getAlternatives(
      {
        productId: alternativeProductSelected.product.id,
        customerId: customerData.id,
      },
      (res) => {
        if (res.validation) {
          res.validation.forEach((element) => {
            message.error(element);
            this.setState({ loadingAlt: false });
          });
        }
        if (res.code === 200) {
          const AltProductList = res.object.map((altPro) => {
            let selectedBranchIndex =
              altPro.branches.findIndex(
                (pro) => pro.branch_id === this.state.customerData.branch.id
              ) || 0;
            return {
              ...altPro,
              val: altPro.interval[0],
              intervalPoint: altPro.interval[0],
              isAlt: true,
              price:
                altPro.branches[
                  selectedBranchIndex === -1 ? 0 : selectedBranchIndex
                ].price,
              totalPrice:
                altPro.branches[
                  selectedBranchIndex === -1 ? 0 : selectedBranchIndex
                ].price * +altPro.interval[0],
              cuttingTypesSelect: altPro.cuttingTypes[0]
                ? altPro.cuttingTypes[0].id
                : null,
            };
          });
          this.setState({ loadingAlt: false, AltProductList });
        }
      },
      (error) => {
        console.log(error);
        this.setState({ loadingAlt: false });
      }
    );
  };

  handelAddAltProduct = (idx) => {
    const { selectedProductList } = this.state;
    const isProductExist = selectedProductList.some(
      (pro) => pro.id === this.state.AltProductList[idx].id
    );
    if (!isProductExist) {
      this.setState({ alternativeProductSelectedIndx: idx });
      document
        .querySelectorAll(".product-alt-wrapper")
        .forEach((ele) => ele.classList.remove("selected"));
      document
        .getElementsByClassName("product-alt-wrapper")
      [idx].classList.add("selected");
      const updateListWithAlt = selectedProductList.filter(
        (pro) => pro.isAlt === false
      );
      this.setState({
        selectedProductList: [
          ...updateListWithAlt,
          this.state.AltProductList[idx],
        ],
      });
      setTimeout(() => {
        this.addUserInfoToLocalStorage();
      }, 1500);
      message.info("تم اختيار بديل للمنتج بنجاح ");
    } else {
      message.warning("المنتج تم اختياره بالفعل");
    }
  };

  handleCloseAltModal = () => {
    this.setState({
      autoCompleteItem: "",
      showAltParagraph: false,
      alternativeProductSelected: null,
      alternativeProductSelectedIndx: null,
      selectedProductList: [...this.state.selectedProductList].map((pro) => ({
        ...pro,
        isAlt: false,
      })),
    });
  };

  handelDltAddress = (address) => {
    confirm({
      title: "هل أنت متأكد من حذف هذا العنوان ؟",
      icon: <ExclamationCircleOutlined style={{ color: "#f60606" }} />,
      content: "",
      okText: "نعم",
      okType: "danger",
      cancelText: "لا",
      wrapClassName: "delete-modal-wrapper",
      onOk: () => {
        deleteUserAddress(
          { id: this.state.customerData.id, addressId: address.id },
          (res) => {
            if (res.data.code === 200) {
              message.success("تم حذف العنوان بنجاح");
              const addresses = [...this.state.customerData.addresses].filter(
                (adres) => adres.id !== address.id
              );
              this.setState({
                customerData: { ...this.state.customerData, addresses },
                selectAdressIdx: 0,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      },
    });
  };

  handelDltPhone = (phone) => {
    confirm({
      title: "هل أنت متأكد من حذف هذا الرقم ؟",
      icon: <ExclamationCircleOutlined style={{ color: "#f60606" }} />,
      content: "",
      okText: "نعم",
      okType: "danger",
      cancelText: "لا",
      wrapClassName: "delete-modal-wrapper",
      onOk: () => {
        deleteUserPhone(
          { id: this.state.customerData.id, phoneId: phone.id },
          (res) => {
            if (res.data.code === 200) {
              message.success("تم حذف الرقم بنجاح");
              if (this.state.customerData.phones.id === phone.id) {
                const [, ...restPhones] = res.data.object;
                this.setState({
                  editPhonesMode: false,
                  editPhoneVal: null,
                  searchVal: res.data.object[0].phone,
                  addPhonesModal: false,
                  customerData: {
                    ...this.state.customerData,
                    phones: res.data.object[0],
                    otherPhones: restPhones,
                  },
                });
              } else {
                this.setState({
                  editPhonesMode: false,
                  editPhoneVal: null,
                  addPhonesModal: false,
                  customerData: {
                    ...this.state.customerData,
                    otherPhones: res.data.object.filter(
                      (phone) => phone.id !== this.state.customerData.phones.id
                    ),
                  },
                });
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      },
    });
  };

  handelEditPhone = (phone) => {
    this.setState({ editPhonesMode: true, editPhoneVal: phone });
    this.formAddPhonesRef.current.setFieldsValue({
      phone: phone.phone,
    });
  };

  handelEditAdress = (address) => {
    const { building, city, apartment, floor, area, streetNumber, landmark } =
      address;
    this.setState({ isEditAddress: address, addAddressVisible: true }, () => {
      setTimeout(() => {
        this.formAddressRef.current.setFieldsValue({
          building,
          city,
          apartment,
          floor,
          area,
          streetNumber,
          landmark,
        });
      }, 300);
    });
  };

  handelSendOrder = () => {
    this.setState({ sendOrderLoading: true });
    const { orderComment, payMethod, selectedProductList, customerData } =
      this.state;
    let orderItems = [];
    selectedProductList.map((item) => {
      orderItems.push({
        id: item.id,
        quantity: +item.val,
        comment: item.comment,
        cuttingType: item.cuttingTypesSelect ? item.cuttingTypesSelect : "",
      });
    });
    let body = {
      id: customerData.id,
      comment: orderComment,
      paymentId: payMethod,
      items: orderItems,
      addressId:
        this.state.customerData.addresses[this.state.selectAdressIdx].id,
    };
    checkoutOrder(
      body,
      (res) => {
        if (res.data.validation) {
          this.setState({ sendOrderLoading: false });
          res.data.validation.forEach((element) => {
            message.error(element);
          });
        }
        if (res.data.code === 200) {
          message.success("تمت إضافة الطلب بنجاح");
          this.setState({ sendOrderLoading: false, orderStepActive: 4 });
        }
      },
      (error) => {
        this.setState({ sendOrderLoading: false });
        console.log(error);
      }
    );
  };

  refreshData = () => {
    localStorage.removeItem("branchesList");
    localStorage.removeItem("citiesList");
    localStorage.removeItem("localState");
    window.location.reload();
  };

  render() {
    const {
      citiesList,
      searchLoading,
      searchVal,
      showValidation,
      customerData,
      isCustomerNew,
      showCustomerNoData,
      showSelcetBrnchModal,
      selectAdressIdx,
      orderStepActive,
      selectedProductList,
      autoCompleteItem,
      orderComment,
      alternativeProductSelected,
      branchesList,
      payMethod,
      loadingAddUser,
      showAltParagraph,
      showAltModal,
      sendOrderLoading,
      AltProductList,
      loadingAlt,
      searchProLoading,
      generalOrderVisible,
      showHistoryOrder,
      addPhonesModal,
      addNameVisible,
    } = this.state;
    return (
      <AccessDB objectStore="products">
        {({ getAll, add, clear }) => {
          return (
            <>
              {orderStepActive > 1 && (
                <p
                  className="user-back"
                  onClick={() => {
                    this.setState({
                      orderStepActive: this.state.orderStepActive - 1,
                    });
                  }}
                >
                  Back <ArrowLeftOutlined />
                </p>
              )}
              {orderStepActive === 1 && (
                <div className="app__container">
                  <div className="app__container--header">
                    <div className="user-info">
                      <p>
                        مرحبا بك ،{" "}
                        <strong>
                          {" "}
                          {JSON.parse(localStorage.getItem("userInfo")) &&
                            JSON.parse(localStorage.getItem("userInfo"))
                              .name}{" "}
                        </strong>{" "}
                      </p>
                      <h4 className="app__container--header-title">
                        قم بعمل طلب جديد
                      </h4>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        type="primary"
                        ghost
                        onClick={this.handelAddCustomerModal}
                      >
                        عميل جديد
                      </Button>
                      <ReloadOutlined
                        onClick={this.refreshData}
                        style={{
                          marginRight: 20,
                          fontSize: 26,
                          cursor: "pointer",
                          color: "#002575",
                        }}
                      />
                    </div>
                  </div>
                  <div className="app__container--contant">
                    <div className="search-input-wrapper">
                      <label htmlFor="" className="search-input-label">
                        ابحث عن عميل{" "}
                      </label>
                      <Search
                        className="search-input"
                        size="large"
                        required
                        allowClear
                        placeholder="ادخل رقم الهاتف الخاص بالعميل"
                        onChange={this.handelSearchChange}
                        onSearch={(e) => {
                          this.onSearch(e);
                        }}
                        value={searchVal}
                        enterButton="بحث"
                        loading={searchLoading}
                        disabled={searchLoading}
                      />
                      {showValidation && (
                        <p className="search-validation">
                          يجب أن يتكون رقم الهاتف بين 8 أرقام أو 11 رقمًا , هذا
                          الرقم {searchVal.length} رقما
                        </p>
                      )}
                    </div>
                    {showCustomerNoData ? (
                      <div className="customer-info-card customer-no-data-wrapper">
                        <p>
                          لا يوجد عميل خاص بهذا الرقم ، قم بإضافة العميل{" "}
                          <span onClick={this.handelAddCustomerModal}>
                            أضافة
                          </span>
                        </p>
                      </div>
                    ) : customerData ? (
                      <>
                        <div className="customer-info-card customer-data-wrapper">
                          {isCustomerNew ? (
                            <div className="customer-hint">
                              <p>تم اضافة العميل للنظام</p>
                              <span
                                className="btn-link"
                                onClick={
                                  () => this.handelEditPhones()
                                  // this.handelAddCustomerModal("edit-mode")
                                }
                              >
                                أظهار ارقام العميل
                              </span>
                            </div>
                          ) : (
                            <div className="customer-hint">
                              <p>
                                تم إيجاد عميل بهذا الرقم في نظام مترو ، يمكنك
                                التعديل علي بياناته او اضافته
                              </p>
                              <span
                                className="btn-link"
                                onClick={() =>
                                  // this.handelAddCustomerModal("edit-mode")
                                  this.handelEditPhones()
                                }
                              >
                                أظهار ارقام العميل
                              </span>
                            </div>
                          )}

                          <div className="customer-name">
                            اسم العميل :{" "}
                            <span>{customerData && customerData.name}</span>
                            <span
                              className="btn-link"
                              onClick={() => {
                                this.setState({ addNameVisible: true }, () => {
                                  setTimeout(() => {
                                    this.formEditName.current.setFieldsValue({
                                      editName: this.state.customerData.name,
                                    });
                                  }, 500);
                                });
                              }}
                            >
                              تعديل{" "}
                            </span>
                          </div>
                          <div className="customer-address">
                            <h6
                              className="customer-address--title"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: 20,
                              }}
                            >
                              <span>عناوين العميل</span>
                              <span
                                className="btn-link"
                                onClick={() =>
                                  this.setState({ addAddressVisible: true })
                                }
                              >
                                اضف عنوان اخر
                              </span>
                            </h6>
                            <Radio.Group
                              onChange={this.onChangeAdress}
                              value={selectAdressIdx}
                            >
                              {customerData &&
                                customerData.addresses.map((address, idx) => (
                                  <>
                                    <Radio key={idx} value={idx}>
                                      <address>
                                        {address.city},&nbsp;&nbsp;
                                        {address.area},&nbsp;&nbsp;
                                        {`شارع ${address.streetNumber},`}
                                        &nbsp;&nbsp;
                                        {`مبني ${address.building},`}
                                        &nbsp;&nbsp;
                                        {address.apartment
                                          ? `شقه ${address.apartment},`
                                          : null}
                                        &nbsp;&nbsp;
                                        {address.floor
                                          ? `الدور ${address.floor},`
                                          : null}
                                        &nbsp;&nbsp;
                                        {address.landmark
                                          ? `${address.landmark},`
                                          : null}
                                        <EditOutlined
                                          onClick={() =>
                                            this.handelEditAdress(address)
                                          }
                                          style={{
                                            fontSize: "20px",
                                            color: "#002575",
                                            fill: "#002575",
                                            cursor: "pointer",
                                            marginRight: 10,
                                          }}
                                        />
                                        {customerData.addresses.length > 1 && (
                                          <DeleteFilled
                                            onClick={() =>
                                              this.handelDltAddress(address)
                                            }
                                            style={{
                                              fontSize: "20px",
                                              color: "#f60606",
                                              fill: "#f60606",
                                              cursor: "pointer",
                                              marginRight: 10,
                                            }}
                                          />
                                        )}
                                      </address>
                                    </Radio>
                                  </>
                                ))}
                            </Radio.Group>
                          </div>
                          <div className="customer-branch">
                            <h6 className="customer-branch--title">
                              اخر فرع قام بخدمة العميل
                            </h6>
                            <div className="brnach-name-holder">
                              {customerData && customerData.branch ? (
                                <div className="brnach-serve-name">
                                  <p>
                                    {customerData.branch.address}&nbsp;&nbsp;
                                    {customerData.branch.name}
                                  </p>
                                  <span
                                    className="btn-link"
                                    onClick={() => {
                                      this.selectServeBranch();
                                      // this.setEditServeBranch();
                                    }}
                                  >
                                    تغيير
                                  </span>
                                </div>
                              ) : (
                                <div className="brnach-no-select">
                                  <p>
                                    لا يوجد فرع قام بخدمة العميل ، قم بأختيار
                                    فرع لاستكمال الطلب
                                  </p>
                                  <span
                                    className="btn-link"
                                    onClick={this.selectServeBranch}
                                  >
                                    اختيار فرع
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          {/* start customer history */}
                          {customerData && customerData.ordersHistory && (
                            <div className="customer-history">
                              <div className="customer-history--header">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  style={{ marginLeft: 10 }}
                                >
                                  <path
                                    fill="#788db9"
                                    fillRule="evenodd"
                                    d="M12.121 9.233h2.768a.766.766 0 000-1.531h-2.768a.766.766 0 000 1.531zm6.055-5.306a1.687 1.687 0 011.409.684 2.207 2.207 0 01.38 1.754l-.949 6.695a2.568 2.568 0 01-2.528 2.235H5.586a2.568 2.568 0 01-2.538-2.387L2.129 1.783.62 1.518A.766.766 0 01.88.009l2.383.366a.765.765 0 01.62.694l.19 2.286a.615.615 0 00.61.573zM5.426 16.908a1.547 1.547 0 101.509 1.551 1.535 1.535 0 00-1.509-1.551zm11.241 0a1.547 1.547 0 101.509 1.551 1.535 1.535 0 00-1.508-1.551z"
                                  ></path>
                                </svg>
                                <p> قام هذا العميل بعمل </p>
                                <span>
                                  {" "}
                                  {
                                    customerData.ordersHistory.numberOfOrders
                                  }{" "}
                                  طلبات{" "}
                                </span>
                                <p> بأجمالي</p>
                                <span>
                                  {" "}
                                  {customerData.ordersHistory.totalOrdersAmount}
                                </span>
                              </div>
                              {customerData.ordersHistory.lastOrderItems
                                .length > 0 && (
                                  <div className="customer-history--collapse">
                                    <Collapse
                                      bordered={false}
                                      expandIconPosition="right"
                                      expandIcon={({ isActive }) => (
                                        <CaretLeftOutlined
                                          rotate={isActive ? -90 : 0}
                                        />
                                      )}
                                      className="site-collapse-custom-collapse"
                                    >
                                      <Panel
                                        header={
                                          <>
                                            تفاصيل اخر طلب
                                            <p style={{ marginRight: 10 }}>
                                              {" "}
                                              بأجمالي
                                            </p>
                                            <span>
                                              {" "}
                                              {
                                                customerData.ordersHistory
                                                  .lastOrderAmount
                                              }
                                            </span>
                                            <CalendarOutlined />
                                            <span
                                              style={{
                                                direction: "ltr",
                                                margin: 0,
                                              }}
                                            >
                                              {
                                                customerData.ordersHistory
                                                  .lastOrderTime
                                              }
                                            </span>
                                          </>
                                        }
                                        key="1"
                                        className="site-collapse-custom-panel"
                                      >
                                        <div className="customer-history-list">
                                          {customerData.ordersHistory.lastOrderItems.map(
                                            (product, idx) => (
                                              <div
                                                className="history-list--product"
                                                key={idx}
                                              >
                                                <div className="pro-holder">
                                                  <img
                                                    src={product.image}
                                                    alt="product image"
                                                  />
                                                </div>
                                                <p className="pro--name">
                                                  {product.name}
                                                </p>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </Panel>
                                    </Collapse>
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                        <div className="btns-action">
                          <Button
                            type="primary"
                            disabled={!!!customerData.branch}
                            onClick={() => {
                              this.addUserInfoToLocalStorage();
                              this.setState({ orderStepActive: 2 });
                              // this.fetchProductsList(add, getAll);
                              this.checkTimeForUpdate(add, getAll, clear);
                            }}
                          >
                            استكمال الطلب
                          </Button>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              )}
              {/* start products component start */}
              {orderStepActive === 2 && (
                <div className="app__container app__container-products">
                  <div className="app__container--header">
                    <div className="user-info">
                      <p>
                        مرحبا بك ،{" "}
                        <strong>
                          {" "}
                          {JSON.parse(localStorage.getItem("userInfo")) &&
                            JSON.parse(localStorage.getItem("userInfo"))
                              .name}{" "}
                        </strong>{" "}
                      </p>
                      <h4 className="app__container--header-title">
                        قم بأضافة المنتجات
                      </h4>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Button
                        type="primary"
                        style={{ marginLeft: "16px" }}
                        disabled={selectedProductList.length === 0}
                        onClick={() =>
                          this.setState({ orderStepActive: 3 }, () => {
                            this.addUserInfoToLocalStorage();
                          })
                        }
                      >
                        ارسال الطلب
                      </Button>
                      <Button
                        type="primary"
                        ghost
                        onClick={() => this.setState({ orderStepActive: 1 })}
                      >
                        ألغاء الطلب
                      </Button>
                    </div>
                  </div>
                  <div className="app__container--header-extra">
                    <Collapse bordered={false}>
                      <Panel
                        header={
                          <div className="collapse-header-info">
                            <p className="collapse-header-info--name">
                              اسم العميل : <strong>{customerData.name}</strong>
                            </p>
                            <p className="collapse-header-info--phone">
                              رقم الهاتف :{" "}
                              <strong>{customerData.phones.phone}</strong>
                            </p>
                          </div>
                        }
                        key="1"
                      >
                        {
                          <p className="collapse-header-info--name">
                            العنوان :{" "}
                            <strong>
                              {customerData.addresses[selectAdressIdx].city}
                              ,&nbsp;
                              {customerData.addresses[selectAdressIdx].area}
                              ,&nbsp;
                              {`شارع ${customerData.addresses[selectAdressIdx].streetNumber},`}
                              &nbsp;
                              {`مبني ${customerData.addresses[selectAdressIdx].building},`}
                              &nbsp;
                              {customerData.addresses[selectAdressIdx].apartment
                                ? `شقه ${customerData.addresses[selectAdressIdx].apartment},`
                                : null}
                              &nbsp;
                              {customerData.addresses[selectAdressIdx].floor
                                ? `الدور ${customerData.addresses[selectAdressIdx].floor},`
                                : null}
                              &nbsp;
                              {customerData.addresses[selectAdressIdx].landmark
                                ? `${customerData.addresses[selectAdressIdx].landmark}`
                                : null}
                              &nbsp;
                            </strong>
                          </p>
                        }
                        {
                          <p className="collapse-header-info--name">
                            الفرع :{" "}
                            <strong>
                              {customerData.branch.address}&nbsp;&nbsp;
                              {customerData.branch.name}
                            </strong>
                          </p>
                        }
                      </Panel>
                    </Collapse>
                    <div className="input-holder">
                      <Form name="global" ref={this.formCommentRef}>
                        <Form.Item name="orderComment" rules={[]}>
                          <Input
                            onClick={(e) => {
                              this.setState({ generalOrderVisible: true });
                            }}
                            style={{ pointer: "cursor" }}
                            defaultValue={orderComment}
                            placeholder="أضف تعليق عام علي الطلب"
                          />
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                  <div className="app__container--contant">
                    <div className="user-order-info">
                      {customerData && customerData.ordersHistory && (
                        <p
                          className="prev-order-btn"
                          onClick={() => {
                            this.setState({ showHistoryOrder: true });
                          }}
                        >
                          طلبات العميل السابقة
                        </p>
                      )}
                      <div className="search__container">
                        <div className="input-holder search-product-input" style={{ position: "relative" }}>
                          <label>ابحث عن منتج </label>
                          <AutoComplete
                            size="large"
                            showSearch
                            dropdownClassName={
                              this.state.openAutoComplete
                                ? "product-dropdown-list"
                                : "hide-product-dropdown-list"
                            }
                            onSelect={this.onSelectProduct}
                            onChange={this.onChangeProduct}
                            onClick={() => {
                              if (!this.state.openAutoComplete) {
                                this.setState({
                                  openAutoComplete: true,
                                });
                              }
                            }}
                            onFocus={() => {
                              this.setState({
                                openAutoComplete: true,
                              });
                            }}
                            onBlur={() => {
                              this.setState({
                                openAutoComplete: false,
                              });
                            }}
                            placeholder="علي سبيل المثال : نسكافية"
                            value={autoCompleteItem}
                            allowClear
                            onClear={() => {
                              this.setState({
                                openAutoComplete: true,
                              });
                            }}
                            loading={searchProLoading}
                            onSearch={(val) => {
                              let filterProductListDB =
                                this.state.productListDB.filter(
                                  (pro) =>
                                    pro.name_ar
                                      .trim()
                                      .toUpperCase()
                                      .includes(val.trim().toUpperCase()) ||
                                    pro.name_en
                                      .trim()
                                      .toUpperCase()
                                      .includes(val.trim().toUpperCase())
                                );
                              this.setState({
                                productListDBTemp: [...filterProductListDB],
                              });
                            }}
                          >
                            {this.state.productListDBTemp
                              .slice(0, 500)
                              .map((product) => (
                                <Option
                                  key={product.id}
                                  product={product}
                                  value={product.name_ar}
                                >
                                  {product.name_ar}
                                  {product.allPrice && (
                                    <div className="demo-option-label-item">
                                      {product.allPrice.old_price !== 0 && (
                                        <span className="price-before-holder">
                                          {product.allPrice.old_price} L.E
                                        </span>
                                      )}
                                      <span className="price-after-holder">
                                        {product.allPrice.price} L.E
                                      </span>
                                    </div>
                                  )}
                                </Option>
                              ))}
                            {this.state.productListDBTemp
                              .slice(0, 500)
                              .map((product) => (
                                <Option
                                  key={"en" + product.id}
                                  product={product}
                                  value={product.name_en}
                                >
                                  {product.name_en}
                                  {product.allPrice && (
                                    <div className="demo-option-label-item">
                                      {product.allPrice.old_price !== 0 && (
                                        <span className="price-before-holder">
                                          {product.allPrice.old_price} L.E
                                        </span>
                                      )}
                                      <span className="price-after-holder">
                                        {product.allPrice.price} L.E
                                      </span>
                                    </div>
                                  )}
                                </Option>
                              ))}
                          </AutoComplete>
                        </div>
                        {showAltParagraph && (
                          <p className="alt-hinte">
                            هذا المنتج غير متوفر حاليا يمكنك اختيار من البدائل{" "}
                            السعر{" "}
                            {
                              alternativeProductSelected.product.branches[0]
                                .price
                            }
                            <span
                              className="btn-link"
                              onClick={() => this.handelAlternativesList()}
                            >
                              اختيار من البدائل
                            </span>{" "}
                          </p>
                        )}
                      </div>
                      <div className="product-selected-list">
                        {selectedProductList.map((product, idx) => (
                          <div className="product-wrapper">
                            <div className="product-info">
                              <div className="product-img">
                                <img
                                  src={product.image}
                                  onError={this.addDefaultSrc}
                                  alt="product image"
                                />
                              </div>
                              <p className="product-name">
                                {product.name_ar}
                                {/* <span>({product.price}</span> EGP ) */}
                              </p>
                            </div>
                            <div className="product-actions">
                              {product.old_price && product.old_price != 0 ? (
                                <p className="product-price product-price-before">
                                  {" "}
                                  EGP {product.old_price.toFixed(2)}
                                </p>
                              ) : null}
                              <p className="product-price">
                                {" "}
                                EGP {product.totalPrice.toFixed(2)}
                              </p>

                              <div className="product-controls">
                                <PlusCircleOutlined
                                  style={{
                                    fontSize: "18px",
                                    color: "#002575",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => this.handelIncreseAmount(idx)}
                                />
                                {/* <p className="product-val">{product.val}</p> */}
                                <input
                                  type="num"
                                  min="1"
                                  className="product-val"
                                  value={product.val}
                                  onChange={(e) =>
                                    this.handelChangeAmount(e, idx)
                                  }
                                />
                                <MinusCircleOutlined
                                  style={{
                                    fontSize: "18px",
                                    color: "#002575",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.handelDecrementAmount(idx)
                                  }
                                />
                              </div>
                              {product.cuttingTypes.length > 0 && (
                                <div className="input-holder" style={{ position: "relative" }}>
                                  <Form.Item>
                                    <Select
                                      style={{ width: 140 }}
                                      defaultValue={product.cuttingTypesSelect}
                                      onChange={(id, type) =>
                                        this.handelChangecuttingTypes(id, type)
                                      }
                                    >
                                      {product.cuttingTypes.map((type) => (
                                        <Option
                                          key={type.id}
                                          value={type.id}
                                          productIdx={idx}
                                        >
                                          {type.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </div>
                              )}
                              <DeleteOutlined
                                style={{
                                  fontSize: "20px",
                                  color: "#f60606",
                                  cursor: "pointer",
                                  marginRight: 16,
                                }}
                                onClick={() =>
                                  this.setState({
                                    showDeleteProduct: true,
                                    productListIdx: idx,
                                  })
                                }
                              />
                            </div>
                            <div className="input-holder">
                              <Form.Item name={product.id} rules={[]}>
                                <Input
                                  onChange={(e) =>
                                    this.handelAddCommentToItem(e, idx)
                                  }
                                  id={`itemComment${idx}`}
                                  defaultValue={product.comment}
                                  placeholder="أضف تعليق علي المنتج"
                                />
                              </Form.Item>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {orderStepActive === 3 && (
                <div className="app__container app_container-summary">
                  <div className="summary-top">
                    <h3 className="summry-title">ملخص الطلب</h3>
                    <div className="summry-user-info">
                      <div className="summry-user-info-header">
                        <p className="summry-user-row">
                          اسم العميل : <strong>{customerData.name}</strong>
                        </p>
                        <p className="summry-user-row">
                          رقم الهاتف :{" "}
                          <strong>{customerData.phones.phone}</strong>
                        </p>
                      </div>
                      <div className="summry-user-info-header">
                        {
                          <p className="summry-user-row">
                            العنوان :{" "}
                            <strong>
                              {customerData.addresses[selectAdressIdx].city}
                              ,&nbsp;
                              {customerData.addresses[selectAdressIdx].area}
                              ,&nbsp;
                              {`شارع ${customerData.addresses[selectAdressIdx].streetNumber},`}
                              &nbsp;
                              {`مبني ${customerData.addresses[selectAdressIdx].building},`}
                              &nbsp;
                              {customerData.addresses[selectAdressIdx].apartment
                                ? `شقه ${customerData.addresses[selectAdressIdx].apartment},`
                                : null}
                              &nbsp;
                              {customerData.addresses[selectAdressIdx].floor
                                ? `الدور ${customerData.addresses[selectAdressIdx].floor},`
                                : null}
                              &nbsp;
                              {customerData.addresses[selectAdressIdx].landmark
                                ? `${customerData.addresses[selectAdressIdx].landmark}`
                                : null}
                              &nbsp;
                            </strong>
                          </p>
                        }
                        <span
                          className="btn-link"
                          onClick={() =>
                            this.handelAddCustomerModal("edit-mode")
                          }
                        >
                          تغيير
                        </span>
                      </div>
                      <div className="summry-user-info-header">
                        {
                          <p className="summry-user-row">
                            الفرع :{" "}
                            <strong>
                              {customerData.branch.address}&nbsp;&nbsp;
                              {customerData.branch.name}
                            </strong>
                          </p>
                        }
                        <span
                          className="btn-link"
                          onClick={() => {
                            this.selectServeBranch();
                            // this.setEditServeBranch();
                          }}
                        >
                          تغيير
                        </span>
                      </div>
                    </div>
                    <div className="order-comment-holder">
                      <div className="input-holder">
                        <Form name="global" ref={this.formCommentRef}>
                          <Form.Item name="orderComment" rules={[]}>
                            <Input
                              onClick={(e) => {
                                this.setState({ generalOrderVisible: true });
                              }}
                              defaultValue={orderComment}
                              placeholder="أضف تعليق عام علي الطلب"
                            />
                          </Form.Item>
                        </Form>
                      </div>
                      {/* <span className="btn-link" onClick={() => this.setState({isDisabledOrderComment : !isDisabledOrderComment})}>تعديل</span>  */}
                    </div>
                    <div className="order-summary-table">
                      <div className="summary-table-header">
                        <h3 className="summary-table-header--title">
                          عناصر الطلب
                        </h3>
                        <span
                          className="btn-link"
                          onClick={() => this.setState({ orderStepActive: 2 })}
                        >
                          أضافة منتج اخر
                        </span>
                      </div>
                      <div className="summary-table-body">
                        <div className="product-selected-list">
                          {selectedProductList.map((product, idx) => {
                            return (
                              <div className="product-wrapper">
                                <div className="summary-item-wrapper">
                                  <div className="product-info">
                                    <div className="product-img">
                                      <img
                                        src={product.image}
                                        onError={this.addDefaultSrc}
                                        alt="product image"
                                      />
                                    </div>
                                    <p className="product-name">
                                      {product.name_ar
                                        ? product.name_ar
                                        : product.name_en}
                                    </p>
                                  </div>
                                  <div className="product-actions">
                                    {product.old_price &&
                                      product.old_price != 0 ? (
                                      <p className="product-price product-price-before">
                                        {" "}
                                        EGP {product.old_price.toFixed(2)}
                                      </p>
                                    ) : null}
                                    <p className="product-price">
                                      {" "}
                                      EGP {product.totalPrice.toFixed(2)}
                                    </p>
                                    <div className="product-controls">
                                      <PlusCircleOutlined
                                        style={{
                                          fontSize: "18px",
                                          color: "#002575",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          this.handelIncreseAmount(idx)
                                        }
                                      />
                                      <input
                                        type="num"
                                        min="1"
                                        className="product-val"
                                        value={product.val}
                                        onChange={(e) =>
                                          this.handelChangeAmount(e, idx)
                                        }
                                      />
                                      {/* <p className="product-val">{product.val}</p> */}
                                      <MinusCircleOutlined
                                        style={{
                                          fontSize: "18px",
                                          color: "#002575",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          this.handelDecrementAmount(idx)
                                        }
                                      />
                                    </div>
                                    {product.cuttingTypes.length > 0 && (
                                      <div className="input-holder">
                                        <Form.Item>
                                          <Select
                                            style={{ width: 140 }}
                                            defaultValue={
                                              product.cuttingTypesSelect
                                            }
                                            onChange={(id, type) =>
                                              this.handelChangecuttingTypes(
                                                id,
                                                type
                                              )
                                            }
                                          >
                                            {product.cuttingTypes.map(
                                              (type) => (
                                                <Option
                                                  key={type.id}
                                                  value={type.id}
                                                  productIdx={idx}
                                                >
                                                  {type.name}
                                                </Option>
                                              )
                                            )}
                                          </Select>
                                        </Form.Item>
                                      </div>
                                    )}
                                    <DeleteOutlined
                                      style={{
                                        fontSize: "20px",
                                        color: "#f60606",
                                        cursor: "pointer",
                                        marginRight: 16,
                                      }}
                                      onClick={() =>
                                        this.showDeleteConfirm(idx)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="summary-input">
                                  <CommentOutlined />
                                  <div className="input-holder">
                                    <Form.Item name={product.id} rules={[]}>
                                      <Input
                                        onChange={(e) =>
                                          this.handelAddCommentToItem(e, idx)
                                        }
                                        id={`itemComment${idx}`}
                                        defaultValue={product.comment}
                                        placeholder="أضف تعليق علي المنتج"
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="summary-table-footer">
                        <div className="payment-row">
                          <h3>طريقة الدفع</h3>
                          <Radio.Group
                            onChange={(e) =>
                              this.setState({ payMethod: e.target.value })
                            }
                            value={payMethod}
                          >
                            <Radio value={1}>الدفع عند الوصول</Radio>
                            <Radio value={2}>الدفع بالفيزا عند الوصول</Radio>
                          </Radio.Group>
                        </div>
                        <div className="total-price-summary">
                          <h3>
                            المجموع{" "}
                            <span>( {selectedProductList.length} منتج )</span>{" "}
                          </h3>
                          <p>
                            {selectedProductList
                              .reduce((item, cur) => item + cur.totalPrice, 0)
                              .toFixed(2)}{" "}
                            EGP
                          </p>
                        </div>
                        <div className="total-price-summary">
                          <h3>رسوم التوصيل</h3>
                          <p>
                            {this.state.customerData.branch.deliveryCharge} EGP
                          </p>
                        </div>
                        <div className="total-price-summary">
                          <h3> أجمالي الطلب</h3>
                          <p>
                            {selectedProductList
                              .reduce(
                                (item, cur) => item + cur.totalPrice,
                                +this.state.customerData.branch.deliveryCharge
                              )
                              .toFixed(2)}{" "}
                            EGP
                          </p>
                        </div>
                      </div>
                      <div className="summary-table-action">
                        <Button
                          type="primary"
                          style={{ marginLeft: "16px" }}
                          loading={sendOrderLoading}
                          onClick={this.handelSendOrder}
                        >
                          ارسال الطلب
                        </Button>
                        <Button
                          type="primary"
                          ghost
                          onClick={() => this.setState({ orderStepActive: 2 })}
                        >
                          ألغاء الطلب
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {orderStepActive === 4 && (
                <div className="app__container app_container-success">
                  <Result
                    status="success"
                    title="تم عمل الطلب بنجاح"
                    extra={[
                      <Button
                        type="primary"
                        key="console"
                        onClick={this.resetAllData}
                      >
                        عمل طلب جديد
                      </Button>,
                    ]}
                  />
                </div>
              )}
              <SelectBranchModal
                showModal={showSelcetBrnchModal}
                closeModal={this.closeSelectServeBranch}
                addServeBranch={(selectedBranch) =>
                  this.addServeBranch(selectedBranch)
                }
                branchesList={branchesList}
              />
              {/* <BranchesModal
          showModal={showSelcetBrnchModal}
          closeModal={this.closeSelectServeBranch}
          addServeBranch={(name, product) => this.addServeBranch(name, product)}
          citiesList={citiesList}
        /> */}
              {showAltModal && (
                <Modal
                  title="قم بأختيار منتج من المنتجات البديلة"
                  width={750}
                  visible={showAltModal}
                  wrapClassName="alt-product-modal"
                  afterClose={this.handleCloseAltModal}
                  onCancel={() => this.setState({ showAltModal: false })}
                  footer={null}
                >
                  <div className="product-selected-list">
                    {loadingAlt ? (
                      <Spin />
                    ) : (
                      AltProductList.map((product, idx) => (
                        <div className={`product-wrapper product-alt-wrapper`}>
                          <div className="product-info">
                            <p className="product-name">{product.name_ar}</p>
                          </div>
                          <div className="product-actions">
                            <p className="product-price">
                              {" "}
                              EGP {product.totalPrice.toFixed(2)}
                            </p>
                            <div className="product-controls">
                              <PlusCircleOutlined
                                style={{
                                  fontSize: "18px",
                                  color: "#002575",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.handelIncreseAlt(idx)}
                              />
                              <p className="product-val">{product.val}</p>
                              <MinusCircleOutlined
                                style={{
                                  fontSize: "18px",
                                  color: "#002575",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.handelDecrementAlt(idx)}
                              />
                            </div>
                            {/* {product.cuttingTypes.length > 0 &&  
                            <div className="input-holder">
                              <Form.Item>
                                <Select style={{ width: 140 }} defaultValue={product.cuttingTypesSelect} onChange={(id, type) => this.handelChangecuttingTypes(id, type)}> 
                                  {product.cuttingTypes.map(type => ( 
                                    <Option key={type.id} value={type.id} productIdx={idx}>{type.name}</Option>   
                                    ))}
                                </Select> 
                              </Form.Item>
                            </div>
                            } */}
                            <Button
                              className="select-al-btn"
                              type="primary"
                              onClick={() => this.handelAddAltProduct(idx)}
                            >
                              اضف المنتج
                            </Button>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </Modal>
              )}
              {this.state.addPhonesModal && (
                <Modal
                  visible={this.state.addPhonesModal}
                  centered
                  closable={false}
                  destroyOnClose
                  wrapClassName="add-user-modal"
                  width="70%"
                  footer={null}
                >
                  <div className="add-customer-wrapper">
                    <Form
                      name="basic"
                      ref={this.formAddPhonesRef}
                      onFinish={this.handelSubmitPhones}
                    >
                      <h4 className="section-title">بيانات العميل</h4>
                      <div className="inputs-group-holder">
                        <div className="input-holder">
                          <Form.Item
                            name="name"
                            rules={[
                              {
                                required: true,
                                message: "من فضلك ادخل اسم العميل",
                              },
                            ]}
                          >
                            <Input placeholder="*أسم العميل" />
                          </Form.Item>
                          {/* <EditOutlined  
                    style={{
                        fontSize: "20px",
                        color: "#000",  
                        fill: "#000", 
                        cursor: "pointer",
                        marginRight : 10,
                      }} 
                    onClick={() => this.handelEditName()} /> */}
                        </div>
                        <div className="input-holder">
                          <Form.Item name="phone" rules={phoneValidation}>
                            <Input placeholder="*اضف رقم اخر" />
                          </Form.Item>
                        </div>
                        {/* {addPhonesModal && customerData.otherPhones.length > 0 && customerData.otherPhones.map((oPhone, idx) => (
                      <div className="input-holder input-dlt-holder">
                      <Form.Item
                        name={`phone${idx + 1}`} 
                        rules={phoneValidation}
                      >
                        <Input placeholder="*رقم الهاتف" /> 
                      </Form.Item>  
                      <MinusCircleOutlined onClick={() => false} />
                    </div> 
                  ))}  
                <Form.List name="phones">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map( (field, idx) => (
                        <div className="input-holder input-dlt-holder">
                            <Form.Item
                              {...field}
                              name={[field.name, `phone${addPhonesModal && customerData.otherPhones.length + 1}`]} 
                              fieldKey={[field.fieldKey, 'phone']}
                              rules={phoneValidation}
                            >
                              <Input placeholder="*رقم الهاتف" /> 
                            </Form.Item> 
                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                          </div> 
                        ))}
                        <div className="input-holder">
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block>
                            اضف رقم اخر
                          </Button>
                        </Form.Item> 
                        </div>
                      </>
                    )} 
                  </Form.List> */}
                      </div>
                      <h4 className="section-title">قائمه الارقام الاخري</h4>
                      <div className="inputs-group-holder">
                        {addPhonesModal && customerData.phones && (
                          <div className="input-holder input-dlt-holder">
                            <Form.Item name={`phonee2`}>
                              <Input
                                defaultValue={customerData.phones.phone}
                                disabled
                                placeholder="*اضف رقم اخر"
                              />
                            </Form.Item>
                            <EditOutlined
                              style={{
                                fontSize: "20px",
                                color: "#000",
                                fill: "#000",
                                cursor: "pointer",
                                marginRight: 10,
                              }}
                              onClick={() =>
                                this.handelEditPhone(customerData.phones)
                              }
                            />
                            {addPhonesModal &&
                              customerData.otherPhones &&
                              customerData.otherPhones.length > 0 && (
                                <DeleteOutlined
                                  style={{
                                    fontSize: "20px",
                                    color: "#f60606",
                                    fill: "#f60606",
                                    cursor: "pointer",
                                    marginRight: 10,
                                  }}
                                  onClick={() =>
                                    this.handelDltPhone(customerData.phones)
                                  }
                                />
                              )}
                          </div>
                        )}
                        {addPhonesModal &&
                          customerData.otherPhones &&
                          customerData.otherPhones.length > 0 &&
                          customerData.otherPhones.map((oPhone, idx) => (
                            <div
                              className="input-holder input-dlt-holder"
                              key={idx}
                            >
                              <Form.Item name={`phone${idx + 1}`}>
                                <Input
                                  defaultValue={oPhone.phone}
                                  disabled
                                  placeholder="*اضف رقم اخر"
                                />
                              </Form.Item>
                              <EditOutlined
                                style={{
                                  fontSize: "20px",
                                  color: "#002575",
                                  fill: "#002575",
                                  cursor: "pointer",
                                  marginRight: 10,
                                }}
                                onClick={() => this.handelEditPhone(oPhone)}
                              />
                              <DeleteOutlined
                                style={{
                                  fontSize: "20px",
                                  color: "#f60606",
                                  fill: "#f60606",
                                  cursor: "pointer",
                                  marginRight: 10,
                                }}
                                onClick={() => this.handelDltPhone(oPhone)}
                              />
                            </div>
                          ))}
                      </div>
                      <div className="modal-btns-action">
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={loadingAddUser}
                        >
                          {this.state.editPhoneVal ? "تعديل" : "حفظ"}
                        </Button>
                        <Button
                          htmlType="button"
                          onClick={() =>
                            this.setState({
                              addPhonesModal: false,
                              editPhoneVal: null,
                            })
                          }
                        >
                          الغاء
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Modal>
              )}
              {this.state.addCustomerVisible && (
                <Modal
                  visible={this.state.addCustomerVisible}
                  centered
                  closable={false}
                  destroyOnClose
                  wrapClassName="add-user-modal"
                  width="70%"
                  footer={null}
                >
                  <div className="add-customer-wrapper">
                    <Form
                      name="basic"
                      ref={this.formRef}
                      onFinish={this.handelAddCustomer}
                    >
                      <h4 className="section-title">بيانات العميل</h4>
                      <div className="inputs-group-holder">
                        <div className="input-holder">
                          <Form.Item name="phone" rules={phoneValidation}>
                            <Input placeholder="*رقم الهاتف" />
                          </Form.Item>
                        </div>
                        <div className="input-holder">
                          <Form.Item
                            name="name"
                            rules={[
                              {
                                required: true,
                                message: "من فضلك ادخل اسم العميل",
                              },
                            ]}
                          >
                            <Input placeholder="*أسم العميل" />
                          </Form.Item>
                        </div>
                        <Form.List name="phones">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map((field, idx) => (
                                <div className="input-holder input-dlt-holder">
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "phone"]}
                                    fieldKey={[field.fieldKey, "phone"]}
                                    rules={phoneValidation}
                                  >
                                    <Input placeholder="*رقم الهاتف" />
                                  </Form.Item>
                                  <MinusCircleOutlined
                                    onClick={() => remove(field.name)}
                                  />
                                </div>
                              ))}
                              <div className="input-holder">
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                  >
                                    اضف رقم اخر
                                  </Button>
                                </Form.Item>
                              </div>
                            </>
                          )}
                        </Form.List>
                      </div>
                      <h4 className="section-title">عنوان العميل</h4>
                      <div className="input-row-holder">
                        <div className="input-layout-col">
                          <div className="input-holder">
                            <Form.Item
                              name="city"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: "من فضلك اختر المدينه",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                autoComplete="false"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder="*المدينة"
                              >
                                {citiesList.map((city) => (
                                  <Option key={city.id} value={city.title}>
                                    {city.title}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="input-holder">
                            <Form.Item
                              name="area"
                              rules={[
                                {
                                  required: true,
                                  message: "من فضلك ادخل اسم المنطقه",
                                },
                              ]}
                            >
                              <Input placeholder="*المنطقة" />
                            </Form.Item>
                          </div>
                          <div className="input-holder">
                            <Form.Item
                              name="streetNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "من فضلك ادخل اسم الشارع",
                                },
                              ]}
                            >
                              <Input placeholder="* أسم الشارع" />
                            </Form.Item>
                          </div>
                          <div className="input-holder">
                            <Form.Item
                              name="building"
                              rules={[
                                {
                                  required: true,
                                  message: "من فضلك ادخل اسم العقار",
                                },
                              ]}
                            >
                              <Input placeholder="*أسم / رقم العقار" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="input-layout-col">
                          <div className="inputs-group-holder">
                            <div className="input-holder">
                              <Form.Item name="apartment">
                                <Input placeholder="الشقة" />
                              </Form.Item>
                            </div>
                            <div className="input-holder">
                              <Form.Item name="floor">
                                <Input placeholder="الدور" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="input-holder">
                            <Form.Item name="landmark">
                              <Input.TextArea
                                placeholder="علامة مميزة"
                                autoSize={{ minRows: 6, maxRows: 8 }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`modal-btns-action ${customerData && "flex-between"
                          }`}
                      >
                        <div style={{ display: "flex" }}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadingAddUser}
                          >
                            حفظ
                          </Button>
                          <Button
                            htmlType="button"
                            onClick={() =>
                              this.setState({ addCustomerVisible: false })
                            }
                          >
                            الغاء
                          </Button>
                        </div>
                        {customerData && (
                          <Button
                            type="primary"
                            ghost
                            className="w-auto"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                addAddressVisible: true,
                                addCustomerVisible: false,
                              });
                            }}
                          >
                            أضافة عنوان جديد
                          </Button>
                        )}
                      </div>
                    </Form>
                  </div>
                </Modal>
              )}
              {this.state.addAddressVisible && (
                <Modal
                  visible={this.state.addAddressVisible}
                  centered
                  closable={false}
                  destroyOnClose
                  wrapClassName="add-user-modal"
                  width="70%"
                  footer={null}
                >
                  <div className="add-customer-wrapper">
                    <Form
                      name="basic"
                      ref={this.formAddressRef}
                      onFinish={this.handelAddAddress}
                    >
                      {this.state.isEditAddress ? (
                        <h4 className="section-title">تعديل عنوان</h4>
                      ) : (
                        <h4 className="section-title">اضافة عنوان</h4>
                      )}
                      <div className="input-row-holder">
                        <div className="input-layout-col">
                          <div className="input-holder">
                            <Form.Item
                              name="city"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: "من فضلك اختر المدينه",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder="*المدينة"
                              >
                                {citiesList.map((city) => (
                                  <Option key={city.id} value={city.title}>
                                    {city.title}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="input-holder">
                            <Form.Item
                              name="area"
                              rules={[
                                {
                                  required: true,
                                  message: "من فضلك ادخل اسم المنطقه",
                                },
                              ]}
                            >
                              <Input placeholder="*المنطقة" />
                            </Form.Item>
                          </div>
                          <div className="input-holder">
                            <Form.Item
                              name="streetNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "من فضلك ادخل اسم الشارع",
                                },
                              ]}
                            >
                              <Input placeholder="* أسم الشارع" />
                            </Form.Item>
                          </div>
                          <div className="input-holder">
                            <Form.Item
                              name="building"
                              rules={[
                                {
                                  required: true,
                                  message: "من فضلك ادخل اسم العقار",
                                },
                              ]}
                            >
                              <Input placeholder="*أسم / رقم العقار" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="input-layout-col">
                          <div className="inputs-group-holder">
                            <div className="input-holder">
                              <Form.Item name="apartment">
                                <Input placeholder="الشقة" />
                              </Form.Item>
                            </div>
                            <div className="input-holder">
                              <Form.Item name="floor">
                                <Input placeholder="الدور" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="input-holder">
                            <Form.Item name="landmark">
                              <Input.TextArea
                                placeholder="علامة مميزة"
                                autoSize={{ minRows: 6, maxRows: 8 }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="modal-btns-action">
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={loadingAddUser}
                        >
                          {/* {this.state.isEditAddress ? "حفظ" : "تعديل" } */}
                          حفظ
                        </Button>
                        <Button
                          htmlType="button"
                          onClick={() =>
                            this.setState({
                              addAddressVisible: false,
                              isEditAddress: false,
                            })
                          }
                        >
                          الغاء
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Modal>
              )}
              {generalOrderVisible && (
                <Modal
                  visible={generalOrderVisible}
                  wrapClassName="selcet-brnach-modal"
                  centered
                  title="اضف تعليق عام علي الطلب"
                  onOk={() => {
                    this.setState(
                      {
                        orderComment: this.state.orderCommentTemp,
                        generalOrderVisible: false,
                      },
                      () => {
                        this.formCommentRef.current.setFieldsValue({
                          orderComment: this.state.orderComment,
                        });
                      }
                    );
                  }}
                  okText="حفظ"
                  onCancel={() => this.setState({ generalOrderVisible: false })}
                  destroyOnClose
                >
                  <div className="input-holder">
                    <Form.Item name="ordeGeneral">
                      <Input.TextArea
                        autoSize={{ minRows: 12 }}
                        onChange={(e) =>
                          this.setState({ orderCommentTemp: e.target.value })
                        }
                        // disabled
                        defaultValue={orderComment}
                        placeholder="أضف تعليق عام علي الطلب"
                      />
                    </Form.Item>
                  </div>
                </Modal>
              )}{" "}
              {addNameVisible && (
                <Modal
                  visible={addNameVisible}
                  wrapClassName="selcet-brnach-modal"
                  centered
                  title="تعديل الاسم"
                  onOk={this.handelEditName}
                  okText="حفظ"
                  onCancel={() => this.setState({ addNameVisible: false })}
                  destroyOnClose
                >
                  <Form
                    name="basic"
                    ref={this.formEditName}
                    onFinish={this.handelAddAddress}
                  >
                    <div className="input-holder">
                      <Form.Item name="editName">
                        <Input placeholder="الاسم" />
                      </Form.Item>
                    </div>
                  </Form>
                </Modal>
              )}
              {showHistoryOrder && (
                <Modal
                  visible={showHistoryOrder}
                  wrapClassName="selcet-brnach-modal"
                  centered
                  title=""
                  onCancel={() => this.setState({ showHistoryOrder: false })}
                  destroyOnClose
                  footer={null}
                >
                  {customerData && customerData.ordersHistory && (
                    <>
                      <div className="customer-history--header">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          style={{ marginLeft: 10 }}
                        >
                          <path
                            fill="#788db9"
                            fillRule="evenodd"
                            d="M12.121 9.233h2.768a.766.766 0 000-1.531h-2.768a.766.766 0 000 1.531zm6.055-5.306a1.687 1.687 0 011.409.684 2.207 2.207 0 01.38 1.754l-.949 6.695a2.568 2.568 0 01-2.528 2.235H5.586a2.568 2.568 0 01-2.538-2.387L2.129 1.783.62 1.518A.766.766 0 01.88.009l2.383.366a.765.765 0 01.62.694l.19 2.286a.615.615 0 00.61.573zM5.426 16.908a1.547 1.547 0 101.509 1.551 1.535 1.535 0 00-1.509-1.551zm11.241 0a1.547 1.547 0 101.509 1.551 1.535 1.535 0 00-1.508-1.551z"
                          ></path>
                        </svg>
                        <p> قام هذا العميل بعمل </p>
                        <span>
                          {" "}
                          {customerData.ordersHistory.numberOfOrders} طلبات{" "}
                        </span>
                        <p> بأجمالي</p>
                        <span>
                          {" "}
                          {customerData.ordersHistory.totalOrdersAmount}
                        </span>
                      </div>
                      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                      <div
                        className="customer-history--header"
                        style={{ marginBottom: 12 }}
                      >
                        <p> تفاصيل اخر طلب </p>
                        <p style={{ marginRight: 10 }}> بأجمالي</p>
                        <span>
                          {" "}
                          {customerData.ordersHistory.lastOrderAmount}
                        </span>
                        <CalendarOutlined />
                        <span style={{ direction: "ltr", margin: 0 }}>
                          {customerData.ordersHistory.lastOrderTime}
                        </span>
                      </div>
                      <div
                        className="customer-history-list"
                        style={{ paddingBottom: 20 }}
                      >
                        {customerData.ordersHistory.lastOrderItems.map(
                          (product, idx) => (
                            <div className="history-list--product" key={idx}>
                              <div className="pro-holder">
                                <img src={product.image} alt="product image" />
                              </div>
                              <p className="pro--name">{product.name}</p>
                            </div>
                          )
                        )}
                      </div>
                    </>
                  )}
                </Modal>
              )}
              {/* delete modal */}
              {this.state.showDeleteProduct && (
                <Modal
                  className="confirm-product-deletion"
                  visible={this.state.showDeleteProduct}
                  title="هل أنت متأكد من حذف هذا المنتج؟"
                  icon={
                    <ExclamationCircleOutlined style={{ color: "#f60606" }} />
                  }
                  okText="نعم"
                  okType="danger"
                  cancelText="لا"
                  bodyStyle={{
                    padding: "0px !important",
                    borderBottom: "none !important",
                  }}
                  wrapClassName="delete-modal-wrapper"
                  onOk={() => {
                    const filterSelectedProductList =
                      this.state.selectedProductList.filter(
                        (_, index) => index !== this.state.productListIdx
                      );
                    this.setState(
                      {
                        selectedProductList: filterSelectedProductList,
                        showDeleteProduct: false,
                        productListIdx: -1,
                      },
                      () => this.forceUpdate()
                    );
                  }}
                  onCancel={(e) => {
                    e.stopPropagation();

                    this.setState({
                      showDeleteProduct: false,
                      productListIdx: -1,
                    });
                  }}
                ></Modal>
              )}
            </>
          );
        }}
      </AccessDB>
    );
  }
}

export default Home;
